import {
  ApolloError,
  MutationHookOptions,
  TypedDocumentNode,
  gql,
  useMutation,
} from '@apollo/client';
import { useNotifications } from '@petmate/ui';
import { AttributeValueType } from 'src/shared/types/attribute.type';
export interface UpdateAttributeData {
  updateAttribute: {
    uuid: string;
    name: string;
    valueType: string;
    values: { uuid?: string; value: string }[];
  };
}

export interface UpdateAttributeVariables {
  uuid: string;
  data: {
    name: string;
    displayName: string;
    description: string;
    valueType: AttributeValueType;
    unit?: string;
    values: { uuid?: string; value: string }[];
  };
}

export const UpdateAttributeMutation: TypedDocumentNode<
  UpdateAttributeData,
  UpdateAttributeVariables
> = gql`
  mutation UpdateAttributeMutation(
    $uuid: String!
    $data: UpdateAttributeInput!
  ) {
    updateAttribute(uuid: $uuid, data: $data) {
      uuid
      name
      valueType
      unit
      values {
        uuid
        value
      }
    }
  }
`;

export interface UpdateAttributeMutationOptions {
  onCompleted?: (data: UpdateAttributeData) => Promise<void>;
  onError?: (error: ApolloError) => void;
}

export const useUpdateAttributeMutation = (
  options: MutationHookOptions<UpdateAttributeData, UpdateAttributeVariables>
) => {
  const { success, error } = useNotifications();

  return useMutation<UpdateAttributeData, UpdateAttributeVariables>(
    UpdateAttributeMutation,
    {
      async onCompleted(data) {
        await options.onCompleted?.(data);
        success({
          title: 'Success',
          message: `Attribute ${data.updateAttribute.name} updated successfully!`,
        });
      },
      onError(e) {
        options.onError?.(e);
        error({
          title: 'Error',
          message: `An error occurred while updating attribute`,
        });
      },
      ...options,
    }
  );
};
