import { Control } from 'react-hook-form';
import { CategoryPicker } from 'src/categories/components/category-picker';
import { Wrapper } from './general-info.styles';
import { TextField } from 'src/shared/components/textfield';
import { TextArea } from 'src/shared/components/text-area';
import { ProductFormValues } from '../../product-form.types';
import { Header, Heading } from '../../product-form.styles';

interface GeneralInfoProps {
  control: Control<ProductFormValues>;
}

export const GeneralInfo = ({ control }: GeneralInfoProps) => {
  return (
    <Wrapper>
      <Header>
        <Heading>Informacje o produkcie</Heading>
      </Header>
      <TextField control={control} label="Name" name="name" />
      <TextField control={control} label="Slug" name="slug" />
      <CategoryPicker control={control} name="category.uuid" />
      <TextArea
        rows={10}
        label="Description"
        name="description"
        control={control}
      />
    </Wrapper>
  );
};
