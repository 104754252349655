import { useNotifications } from '@petmate/ui';
import { useNavigate } from 'react-router-dom';
import { Role } from 'src/auth/hooks/use-can';
import { useProtectedRoute } from 'src/auth/hooks/use-protected-route';
import { ProductForm } from 'src/products/forms/product-form';
import { useCreateProductMutation } from 'src/products/forms/product-form/api/mutations/create-product.mutation';
import { formValuesToApiData } from 'src/products/forms/product-form/product-form.mappers';
import { ProductFormValues } from 'src/products/forms/product-form/product-form.types';
import { Page } from 'src/shared/components/page';
import { PageHeader } from 'src/shared/components/page-header';

export const CreateProduct = () => {
  useProtectedRoute([Role.Admin]);
  const navigate = useNavigate();
  const { success, error } = useNotifications();
  const [createProduct] = useCreateProductMutation({
    onCompleted: () => {
      success({
        message: 'Product created successfully',
        title: 'Success',
      });
      navigate(`/products`);
    },
    onError() {
      error({ message: 'Error creating product', title: 'Error occured' });
    },
  });

  const onSubmit = (values: ProductFormValues) => {
    createProduct({
      variables: formValuesToApiData(values),
    });
  };

  return (
    <Page width={100}>
      <PageHeader title="Create product" canGoBack />
      <ProductForm submitLabel="Create product" onSubmit={onSubmit} />
    </Page>
  );
};
