import { RelatedUuid } from 'src/shared/types/related-uuid.type';
import { CreateProductVariables } from './api/mutations/create-product.mutation';
import {
  ProductFormValues,
  ProductVariantFormValues,
} from './product-form.types';
import { GetProductDetailsData } from 'src/products/pages/show-product/api/queries/get-product-details.query';
import { UpdateProductVariables } from 'src/products/pages/update-product/api/mutations/update-product.mutation';

const parseVariantAttributes = (attributes?: Record<string, string>) => {
  if (!attributes) return [];
  return Object.entries(attributes).map(([attributeUuid, value]) => ({
    attributeUuid,
    value: String(value),
  }));
};

const parseVariantPhotos = (photos: RelatedUuid[]) => {
  return photos
    .filter((photo) => Boolean(photo))
    .map((photo) => ({
      uuid: photo.uuid,
    }));
};

const parseVariant = (variant: ProductVariantFormValues) => {
  return {
    uuid: variant.uuid,
    name: variant.name,
    ean: variant.ean,
    sku: variant.sku,
    price: Number(variant.price),
    stock: Number(variant.stock),
    default: Boolean(variant.default),
    photos: parseVariantPhotos(variant.photos),
    attributes: parseVariantAttributes(variant.attributes),
  };
};

export const formValuesToApiData = (
  values: ProductFormValues
): CreateProductVariables | UpdateProductVariables => {
  return {
    data: {
      name: values.name,
      slug: values.slug,
      description: values.description,
      category: values.category,
      variants: values.variants.map(parseVariant),
    },
  };
};

export const apiDataToFormValues = (
  data: GetProductDetailsData
): ProductFormValues => ({
  uuid: data.product.uuid,
  name: data.product.name,
  slug: data.product.slug,
  description: data.product.description,
  category: {
    uuid: data.product.category.uuid,
  },
  variants: data.product.variants.map((variant) => ({
    uuid: variant.uuid,
    name: variant.name,
    ean: variant.ean,
    sku: variant.sku,
    price: variant.price,
    stock: variant.stock,
    default: variant.default,
    photos: variant.photos,
    attributes: variant.attributes?.reduce((acc, attribute) => {
      return {
        ...acc,
        [attribute.attribute.uuid]: attribute.value,
      };
    }, {}),
  })),
});
