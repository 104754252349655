import {
  SuspenseQueryHookOptions,
  TypedDocumentNode,
  gql,
  useSuspenseQuery,
} from '@apollo/client';
import { FilterInput } from 'src/shared/types/filter-input.type';
import { Product } from 'src/shared/types/product.type';

interface GetProductsQueryVariables {
  filters?: {
    parentUuid?: FilterInput;
  };
  relations?: string[];
}

interface GetProductsQueryData {
  products: {
    payload: Product[];
  };
}

export const GetProductsQuery: TypedDocumentNode<
  GetProductsQueryData,
  GetProductsQueryVariables
> = gql`
  query GetProducts {
    products {
      payload {
        uuid
        name
        description
        category {
          name
        }
        variants {
          uuid
          name
          ean
          sku
          price
          stock
          attributes {
            uuid
          }
          photos {
            uuid
            fileUrl
          }
        }
      }
      pagination {
        total
        totalPages
        pageSize
        page
      }
    }
  }
`;

export interface UseGetProductsQuery extends SuspenseQueryHookOptions {
  variables?: GetProductsQueryVariables;
}

export const useGetProductsQuery = (options: UseGetProductsQuery = {}) => {
  return useSuspenseQuery<GetProductsQueryData, GetProductsQueryVariables>(
    GetProductsQuery,
    options
  );
};
