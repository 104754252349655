import { Icon, IconButton, ProgressCircle } from '@petmate/ui';
import styled from 'styled-components';

export const RemoveButton = styled(IconButton)`
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  position: absolute;
  top: -16px;
  right: -16px;
  border-radius: 50%;
  box-shadow: 2px 2px 10px 2px ${({ theme }) => theme.colors.grayscale[200]};
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
`;

export const Wrapper = styled.div`
  border: 2px dashed ${({ theme }) => theme.colors.grayscale[300]};
  border-radius: 4px;
  width: 100%;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 24px;

  &:hover ${RemoveButton} {
    opacity: 1;
  }
`;

export const PictureIcon = styled(Icon)`
  font-size: 32px;
  color: ${({ theme }) => theme.colors.grayscale[400]};
`;

export const Preview = styled.img`
  max-width: 100%;
  height: auto;
  object-fit: cover;
  transition: opacity 0.1s ease-in-out;
`;

export const Progress = styled(ProgressCircle)`
  position: absolute;
  top: -13px;
  right: -13px;
`;
