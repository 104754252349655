import {
  ApolloError,
  MutationHookOptions,
  TypedDocumentNode,
  gql,
  useMutation,
} from '@apollo/client';
import { useNotifications } from '@petmate/ui';
import { GetAttributesQuery } from '../queries/get-attributes.query';

interface CreateAttributeData {
  createAttribute: {
    uuid: string;
    displayName: string;
    name: string;
    valueType: string;
  };
}

interface CreateAttributeVariables {
  data: {
    name: string;
    displayName: string;
    description: string;
    valueType: string;
    values: { uuid?: string; value: string }[];
  };
}

export const CreateAttributeMutation: TypedDocumentNode<
  CreateAttributeData,
  CreateAttributeVariables
> = gql`
  mutation CreateAttribute($data: CreateAttributeInput!) {
    createAttribute(data: $data) {
      uuid
      name
      unit
      displayName
      valueType
    }
  }
`;

export const useCreateAttributeMutation = (
  options?: MutationHookOptions<CreateAttributeData, CreateAttributeVariables>
) => {
  const { success, error } = useNotifications();

  return useMutation<CreateAttributeData, CreateAttributeVariables>(
    CreateAttributeMutation,
    {
      async onCompleted(data) {
        options?.onCompleted?.(data);
        success({
          title: 'Success',
          message: `Attribute ${data.createAttribute.name} created successfully!`,
        });
      },
      onError(e) {
        options?.onError?.(e);
        error({
          title: 'Error',
          message: 'An error occurred while creating the attribute',
        });
      },
      refetchQueries: [{ query: GetAttributesQuery }],
      awaitRefetchQueries: true,
      ...options,
    }
  );
};
