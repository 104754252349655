import {
  TextArea as TextAreaComponent,
  TextAreaProps as TextAreaComponentProps,
} from '@petmate/ui';
import { Control, FieldValues, Path, useController } from 'react-hook-form';

export interface TextAreaProps<T extends FieldValues>
  extends Omit<TextAreaComponentProps, 'value' | 'onBlur' | 'onChange'> {
  control: Control<T>;
  name: Path<T>;
}

export const TextArea = <T extends FieldValues>({
  control,
  ...props
}: TextAreaProps<T>) => {
  const { field, fieldState } = useController({ control, name: props.name });

  const error = fieldState.error?.message || undefined;
  return <TextAreaComponent {...props} {...field} error={error} />;
};
