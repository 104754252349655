import {
  ApolloError,
  gql,
  TypedDocumentNode,
  useMutation,
} from '@apollo/client';

export interface CreateProductVariables {
  data: {
    name: string;
    slug: string;
    description: string;
    category: {
      uuid: string;
    };
    variants: {
      name: string;
      ean: string;
      sku: string;
      price: number;
      stock: number;
      default: boolean;
      photos: {
        uuid: string;
      }[];
    }[];
  };
}

export interface CreateProductData {
  createProduct: {
    uuid: string;
    name: string;
    slug: string;
    description: string;
    category: {
      uuid: string;
    };
  };
}

export const CreateProductMutation: TypedDocumentNode<
  CreateProductData,
  CreateProductVariables
> = gql`
  mutation CreateProductMutation($data: CreateProductInput!) {
    createProduct(data: $data) {
      uuid
      name
      slug
      description
      category {
        uuid
      }
    }
  }
`;

export interface UseCreateProductMutationOptions {
  onCompleted?: (data: CreateProductData) => void;
  onError?: (error: ApolloError) => void;
}

export const useCreateProductMutation = (
  options?: UseCreateProductMutationOptions
) => {
  return useMutation<CreateProductData, CreateProductVariables>(
    CreateProductMutation,
    options
  );
};
