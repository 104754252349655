import { ButtonGroup, ConfirmModal, IconButton } from '@petmate/ui';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Role, useCan } from 'src/auth/hooks/use-can';

import { paths } from 'src/shared/constants/paths';

import { Product } from 'src/shared/types/product.type';
import { useDeleteProductMutation } from './api/mutations/delete-product.mutation';

export const ListProductsActions = ({ row }: { row: Product }) => {
  const { can } = useCan();
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteProduct] = useDeleteProductMutation();

  const handleModalSubmit = () => {
    setModalOpen(false);
    deleteProduct({ variables: { uuid: row.uuid } });
  };

  return (
    <ButtonGroup>
      <IconButton
        as={Link}
        to={paths.products.show(row.uuid)}
        iconSize={16}
        variant="info"
        name="eye"
      />
      {can([Role.Admin, Role.Manager]) && (
        <IconButton
          as={Link}
          to={paths.products.edit(row.uuid)}
          iconSize={16}
          variant="warning"
          name="edit"
        />
      )}
      {can([Role.Admin]) && (
        <IconButton
          iconSize={16}
          variant="error"
          name="trash"
          onClick={() => setModalOpen(true)}
        />
      )}
      <ConfirmModal
        cancelText="Nie, zostaw"
        confirmText="Tak, usuń"
        title={`Czy na pewno chcesz usunąć produkt: ${row.name}?`}
        onCancel={() => setModalOpen(false)}
        onConfirm={handleModalSubmit}
        open={modalOpen}
      />
    </ButtonGroup>
  );
};
