import styled from 'styled-components';

export const Wrapper = styled.div<{ $width: number }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  row-gap: 24px;

  @media (min-width: 1680px) {
    width: ${({ $width }) => $width}%;
  }
`;
