import { useParams } from 'react-router-dom';
import { Page } from 'src/shared/components/page';
import { useGetProductDetailsQuery } from './api/queries/get-product-details.query';
import {
  DataTable,
  DataTableColumn,
  InfoTable,
  Select,
  Tabs,
} from '@petmate/ui';
import { ProductVariant } from 'src/shared/types/product-variant.type';
import {
  Description,
  Header,
  Heading,
  Photos,
  PhotosNavigation,
  PhotosPreview,
  PhotosThumb,
  ProductDetails,
  Tab,
  VariantSelector,
  VariantSelectorLabel,
} from './show-product.styles';
import { useMemo, useState } from 'react';

import { useProtectedRoute } from 'src/auth/hooks/use-protected-route';
import { Role } from 'src/auth/hooks/use-can';

const columns: DataTableColumn<ProductVariant>[] = [
  {
    label: 'Name',
    accessor: 'name',
  },
  {
    label: 'Price',
    accessor: 'price',
    align: 'center',
  },
  {
    label: 'Stock',
    accessor: 'stock',
    align: 'center',
  },
  {
    label: 'SKU',
    accessor: 'sku',
  },
  {
    label: 'EAN',
    accessor: 'ean',
  },
];

export const ShowProduct = () => {
  useProtectedRoute([Role.Admin]);
  const { uuid = '' } = useParams<{ uuid: string }>();
  const { data } = useGetProductDetailsQuery(uuid);
  const [selectedVariant, setSelectedVariant] = useState<string>(
    data.product.variants[0].uuid
  );

  const { variants } = data.product;
  const variant = useMemo(
    () => variants.find((variant) => variant.uuid === selectedVariant),
    [selectedVariant, variants]
  );

  return (
    <Page width={60}>
      <Header>
        <Photos>
          <PhotosNavigation>
            {variant?.photos.map((photo) => (
              <PhotosThumb key={photo.uuid}>
                <img src={photo.fileUrl} alt={photo.uuid} />
              </PhotosThumb>
            ))}
          </PhotosNavigation>
          <PhotosPreview>
            <img
              src={variant?.photos[0].fileUrl}
              alt={variant?.photos[0].uuid}
            />
          </PhotosPreview>
        </Photos>
        <ProductDetails>
          <Heading>{data.product.name}</Heading>
          <VariantSelector>
            <VariantSelectorLabel>Variant</VariantSelectorLabel>
            <Select
              name="variant"
              label="Variant"
              value={selectedVariant}
              onChange={(value) => {
                setSelectedVariant(value);
              }}
              options={variants.map((variant) => ({
                label: variant.name,
                value: variant.uuid,
              }))}
            />
          </VariantSelector>
          <InfoTable
            align="left"
            rows={[
              { label: 'EAN', value: variant?.ean },
              { label: 'SKU', value: variant?.sku },
              { label: 'Stan', value: variant?.stock + ' szt.' },
              { label: 'Cena', value: variant?.price + ',00 zł' },
            ]}
          />
        </ProductDetails>
      </Header>
      <Tabs
        mode="content"
        items={[
          {
            key: 'general-informations',
            label: 'General informations',
            component: (
              <Tab>
                <Description>{data.product.description}</Description>
              </Tab>
            ),
          },
          {
            key: 'attributes',
            label: 'Attributes',
            component: (
              <Tab>
                <InfoTable
                  rows={(variant?.attributes || []).map((attr) => ({
                    label: attr.attribute.name,
                    value: attr.value,
                  }))}
                />
              </Tab>
            ),
          },
          {
            key: 'variants',
            label: 'Variants',
            component: (
              <Tab>
                <DataTable columns={columns} data={variants} />
              </Tab>
            ),
          },
        ]}
      />
    </Page>
  );
};
