import { useEffect, useRef, useState } from 'react';
import {
  PictureIcon,
  Preview,
  Progress,
  RemoveButton,
  Wrapper,
} from './photo-input.styles';

import { Input } from 'src/products/forms/product-form/components/product-variants/product-variants.styles';
import { Control, useController } from 'react-hook-form';
import { useUploadProductPhotoMutation } from 'src/products/forms/product-form/api/requests/upload-product-photo.request';
import { useRemoveProductPhotoMutation } from 'src/products/forms/product-form/api/requests/remove-product-photo.request';

export interface PhotoInputProps {
  control: Control<any>;
  name: string;
}

export const PhotoInput = ({ name, control }: PhotoInputProps) => {
  const { field } = useController({ control, name });
  const imgRef = useRef<HTMLImageElement>(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const { mutate: uploadPhoto, isLoading } = useUploadProductPhotoMutation({
    onUploadProgress: (progress) => setUploadProgress(progress),
  });
  const { mutate: removePhoto } = useRemoveProductPhotoMutation();

  useEffect(() => {
    if (imgRef.current && field.value) {
      imgRef.current.src = field.value.fileUrl;
    }
  }, [field]);

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && imgRef.current) {
      imgRef.current.src = URL.createObjectURL(file);
      await uploadPhoto(file, {
        onSuccess(data) {
          field.onChange(data);
        },
        onError(error) {
          imgRef.current!.src = '';
          field.onChange(null);
        },
      });
    }
  };

  const handleRemove = async () => {
    await removePhoto(field.value.uuid);

    field.onChange(null);
    if (imgRef.current) {
      imgRef.current.src = '';
    }
  };

  return (
    <Wrapper>
      {!field.value && (
        <>
          <PictureIcon name="picture" size={48} />
          <Input
            value={field.value}
            accept="image/*"
            type="file"
            onChange={handleChange}
          />
        </>
      )}

      {field.value && (
        <RemoveButton onClick={handleRemove} name="trash" iconSize={16} />
      )}
      {isLoading && (
        <Progress
          size={28}
          progress={uploadProgress * 100}
          strokeWidth={2}
        />
      )}

      <Preview style={{ opacity: isLoading ? 0.5 : 1 }} ref={imgRef} />
    </Wrapper>
  );
};
