import axios from 'axios';
import { useMutation } from 'react-query';

export const removeProductPhotoRequest = async (uuid: string) => {
  const url = `${import.meta.env.VITE_API_URL}/files/${uuid}`;
  const response = await axios.delete(url);
  return response.data;
};

export const useRemoveProductPhotoMutation = () => {
  return useMutation({
    mutationKey: ['removeProductPhoto'],
    mutationFn: (uuid: string) => removeProductPhotoRequest(uuid),
  });
};
