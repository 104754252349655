import { gql, TypedDocumentNode, useMutation } from '@apollo/client';

export interface UpdateProductVariables {
  uuid: string;
  data: {
    name: string;
    slug: string;
    description: string;
    category: {
      uuid: string;
    };
    variants: {
      uuid: string;
      name: string;
      ean: string;
      sku: string;
      price: number;
      stock: number;
      default: boolean;
      photos: {
        uuid: string;
      }[];
    }[];
  };
}

export interface UpdateProductData {
  updateProduct: {
    uuid: string;
    name: string;
    slug: string;
    description: string;
    category: {
      uuid: string;
    };
  };
}

export const UpdateProductMutation: TypedDocumentNode<
  UpdateProductData,
  UpdateProductVariables
> = gql`
  mutation UpdateProductMutation($uuid: String!, $data: UpdateProductInput!) {
    updateProduct(uuid: $uuid, data: $data) {
      uuid
      name
      slug
      description
      category {
        uuid
      }
    }
  }
`;

export const useUpdateProductMutation = () => {
  return useMutation<UpdateProductData, UpdateProductVariables>(
    UpdateProductMutation
  );
};
