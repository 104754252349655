import { useRef } from 'react';
import { Input, Tag, Wrapper } from './tags-input.styles';
import { Icon } from '@petmate/ui';
import { theme } from 'src/shared/theme';
import { Control, FieldValues, Path, useController } from 'react-hook-form';

export interface TagsInputProps<T extends FieldValues> {
  name: Path<T>;
  control: Control<T>;
}

export type Tag = {
  value: string;
};

export const TagsInput = <T extends FieldValues>({
  name,
  control,
}: TagsInputProps<T>) => {
  const { field, fieldState } = useController({ name, control });
  const inputRef = useRef<HTMLInputElement>(null);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const input = inputRef.current;
    if (e.key === 'Backspace' && !input?.value && field.value.length) {
      field.onChange(field.value.slice(0, field.value.length - 1));
    }
    if (e.key === 'Enter' && input) {
      e.preventDefault();
      const value = input.value;
      if (value) {
        field.onChange([...field.value, { value }]);
        input.value = '';
      }
    }
  };

  const handleRemove = (index: number) => {
    field.onChange(field.value.filter((_, i) => i !== index));
  };

  return (
    <div>
      <Wrapper data-testid="tags-wrapper" onKeyDown={handleKeyDown}>
        {field.value.map((tag: Tag, idx: number) => (
          <Tag data-testid="tags-tag" key={idx}>
            <span>{tag.value}</span>
            <Icon
              onClick={() => handleRemove(idx)}
              name="close"
              size={12}
              color={theme.colors.grayscale[700]}
            />
          </Tag>
        ))}
        <Input
          data-testid="tags-input"
          placeholder="Value..."
          ref={inputRef}
          onKeyDown={handleKeyDown}
        />
      </Wrapper>
      {fieldState.error && <span>{fieldState.error.message}</span>}
    </div>
  );
};
