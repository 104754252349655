import { isValidBarcode } from 'src/shared/utils/is-valid-ean';
import * as yup from 'yup';

export const productPhotoSchema = yup.object().shape({
  uuid: yup.string().required(),
  fileUrl: yup.string().required(),
  url: yup.string().required(),
  key: yup.string().required(),
  originalName: yup.string().required(),
  type: yup.string().required(),
});

export const productVariantSchema = yup.object().shape({
  name: yup.string().required(),
  ean: yup
    .string()
    .test('ean', 'Nieprawidłowy EAN', (value) => {
      return isValidBarcode(value || '');
    })
    .required(),
  sku: yup.string().required(),
  price: yup.number().required(),
  stock: yup.number().required(),
  default: yup.boolean().required(),
  attributes: yup.object().required(),
  photos: yup
    .array()
    .test('photos', 'field_required', (value) => {
      return (value || []).filter((photo) => photo).length > 0;
    })
    .required(),
});

export const validationSchema = yup.object().shape({
  name: yup.string().required(),
  slug: yup.string().required(),
  description: yup.string().required(),
  category: yup
    .object()
    .shape({
      uuid: yup.string().required(),
    })
    .required(),
  variants: yup.array().of(productVariantSchema).min(1).required(),
});
