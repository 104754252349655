import styled from 'styled-components';

export const Wrapper = styled.div<{ $gap?: number; $columns: string[] }>`
  display: grid;
  grid-template-columns: ${(props) => props.$columns.join(' ')};
  gap: ${(props) => props.$gap || 0}px;
  justify-content: stretch;

  & > * {
    flex: 1;
  }
`;
