import '@petmate/ui/dist/style.css';
import '@petmate/ui/dist/theme/index.scss';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
  createHttpLink,
} from '@apollo/client';
import { NotificationsProvider } from '@petmate/ui';
import { RouteObject, useRoutes } from 'react-router-dom';
import { Layout } from './components/layout';
import { Provider as LayoutProvider } from './context/layout.context';
import { categoriesRoutes } from '../categories/routes';
import { PropsWithChildren, Suspense } from 'react';
import { attributesRoutes } from '../attributes/routes';
import { authRoutes } from 'src/auth/routes';
import { ThemeProvider } from 'styled-components';
import { theme } from 'src/shared/theme';
import { AuthProvider } from 'src/auth/context/auth.context';
import { usersRoutes } from 'src/users/routes';
import { productsRoutes } from 'src/products/routes';
import { QueryClient, QueryClientProvider } from 'react-query';

const httpLink = createHttpLink({
  uri: import.meta.env.VITE_API_URL + '/graphql',
  credentials: 'include',
});

export const client = new ApolloClient({
  uri: import.meta.env.VITE_API_URL + '/graphql',
  cache: new InMemoryCache(),
  link: ApolloLink.from([httpLink]),
  connectToDevTools: true,
});

const layoutRoutes: RouteObject[] = [
  {
    Component: Layout,
    path: '/',
    children: [
      ...categoriesRoutes,
      ...attributesRoutes,
      ...usersRoutes,
      ...productsRoutes,
    ],
  },
];

const routes = [...authRoutes, ...layoutRoutes];

const Providers = ({ children }: PropsWithChildren) => {
  const queryClient = new QueryClient();
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <ApolloProvider client={client}>
          <Suspense>
            <AuthProvider>
              <Suspense fallback={<div>Loading...</div>}>
                <NotificationsProvider>
                  <LayoutProvider>{children}</LayoutProvider>
                </NotificationsProvider>
              </Suspense>
            </AuthProvider>
          </Suspense>
        </ApolloProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export function App() {
  const router = useRoutes(routes);
  return <Providers>{router}</Providers>;
}

export default App;
