import styled from 'styled-components';

export const Header = styled.header`
  padding: 16px 0 16px 0;
  border-bottom: 1px solid var(--gray-300);
  margin-bottom: 16px;
`;

export const Heading = styled.h1`
  font-size: 18px;
  margin: 0;
  font-weight: 500;
  color: var(--gray-900);
  line-height: 28px;
`;

export const Form = styled.form``;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  gap: 96px;
`;

export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px 40px;
  background-color: var(--white);
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.04);
`;
