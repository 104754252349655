import { ProductForm } from 'src/products/forms/product-form';
import { Page } from 'src/shared/components/page';
import { PageHeader } from 'src/shared/components/page-header';
import { useGetProductDetailsQuery } from '../show-product/api/queries/get-product-details.query';
import { useParams } from 'react-router-dom';
import { ProductFormValues } from 'src/products/forms/product-form/product-form.types';
import {
  apiDataToFormValues,
  formValuesToApiData,
} from 'src/products/forms/product-form/product-form.mappers';
import {
  UpdateProductVariables,
  useUpdateProductMutation,
} from './api/mutations/update-product.mutation';

export const UpdateProduct = () => {
  const { uuid = '' } = useParams<{ uuid: string }>();
  const { data } = useGetProductDetailsQuery(uuid);

  const [updateProduct] = useUpdateProductMutation();

  const handleSubmit = (values: ProductFormValues) => {
    updateProduct({
      variables: {
        ...(formValuesToApiData(values) as UpdateProductVariables),
        uuid,
      },
    });
  };

  return (
    <Page width={100}>
      <PageHeader canGoBack title={`Update product: ${data.product.name}`} />
      <ProductForm
        submitLabel="Update product"
        onSubmit={handleSubmit}
        values={apiDataToFormValues(data)}
      />
    </Page>
  );
};
