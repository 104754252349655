import { DataTableColumn, Icon } from '@petmate/ui';
import { Category } from 'src/shared/types/category.type';
import { Name } from './list-categories.styles';

export const columns: DataTableColumn<Category>[] = [
  {
    label: 'Name',
    accessor: 'name',
    render: ({ name, uuid }) => <Name to={`/categories/${uuid}`}>{name}</Name>,
    width: '70%',
    sortable: true,
  },
  {
    label: 'Published',
    accessor: 'published',
    render: ({ published }) => (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Icon
          name={published ? 'eye' : 'eyeHide'}
          size={24}
          color={published ? 'var(--green-300)' : 'var(--gray-300)'}
        />
      </div>
    ),
    align: 'center',
    width: '10%',
  },
  {
    label: 'Subcategories',
    accessor: 'children',
    render: ({ children }) => children.length,
    align: 'center',
    width: '10%',
  },
  {
    label: 'Order',
    accessor: 'order',
    align: 'center',
    width: '10%',
    sortable: true,
  },
];
