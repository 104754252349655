import { useEffect } from 'react';
import slugify from 'slugify';
import { useFormContext } from 'react-hook-form';
import { CategoryPicker } from 'src/categories/components/category-picker';
import { TextField } from 'src/shared/components/textfield';
import { CheckBox } from 'src/shared/components/checkbox';
import { Content } from './info-tab.styles';
import { CategoryFormValues } from 'src/categories/forms/category-form/category-form.types';

export const Info = () => {
  const { watch, setValue, control } = useFormContext<CategoryFormValues>();

  const name$ = watch('name');

  useEffect(() => {
    setValue('slug', slugify(name$, { lower: true }));
  }, [name$, setValue]);

  return (
    <Content data-testid={TEST_IDS.content}>
      <TextField
        data-testid={TEST_IDS.nameInput}
        label="Name"
        name="name"
        control={control}
      />
      <TextField
        data-testid={TEST_IDS.slugInput}
        label="Slug"
        name="slug"
        control={control}
      />
      <CategoryPicker control={control} name="parentUuid" />
      <TextField
        data-testid={TEST_IDS.orderInput}
        label="Order"
        name="order"
        control={control}
      />
      <CheckBox
        data-testid={TEST_IDS.publishedInput}
        label="Published"
        name="published"
        control={control}
        value="true"
      />
    </Content>
  );
};

const TEST_IDS = {
  content: 'info-tab-content',
  nameInput: 'name-input',
  slugInput: 'slug-input',
  orderInput: 'order-input',
  publishedInput: 'published-input',
};
