import {
  SuspenseQueryHookOptions,
  TypedDocumentNode,
  gql,
  useSuspenseQuery,
} from '@apollo/client';
import { Category } from 'src/shared/types/category.type';

import { FilterInput } from 'src/shared/types/filter-input.type';
import { SortInput } from 'src/shared/types/sort-input.type';

interface GetCategoriesVariables {
  filters?: {
    parentUuid?: FilterInput;
  };
  relations?: string[];
  sort?: SortInput;
}

interface GetCategoriesData {
  categories: Category[];
}

export const GetCategoriesQuery: TypedDocumentNode<GetCategoriesData> = gql`
  query GetCategoriesQuery(
    $filters: CategoriesFiltersInput
    $relations: [String!]
    $sort: CategoriesSortInput
  ) {
    categories(filters: $filters, relations: $relations, sort: $sort) {
      uuid
      name
      order
      attributes {
        uuid
        name
        valueType
        values {
          uuid
          value
        }
      }
      children {
        uuid
      }
    }
  }
`;

export const useGetCategoriesQuery = (
  options?: SuspenseQueryHookOptions<GetCategoriesData, GetCategoriesVariables>
) => {
  return useSuspenseQuery<GetCategoriesData, GetCategoriesVariables>(
    GetCategoriesQuery,
    options
  );
};
