import { Button, Modal } from '@petmate/ui';
import { Header, Heading } from '../../product-form.styles';
import { Row } from 'src/shared/components/row';
import { TextField } from 'src/shared/components/textfield';
import { CheckBox } from 'src/shared/components/checkbox';
import {
  Cell,
  InfoTable,
  Row as TableRow,
} from '../product-variants/product-variants.styles';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Attribute } from 'src/shared/types/attribute.type';
import { BasicInfo, Form, Photos } from './product-variant-modal.styles';
import { PhotoInput } from 'src/shared/components/photo-input';
import { renderAttributeField } from './renderers';
import { yupResolver } from 'src/shared/validation/resolver/yup';
import { useIsMutating } from 'react-query';
import { productVariantSchema } from '../../product-form.validation';
import { File } from 'src/shared/types/file.type';
import { ProductVariantFormValues } from '../../product-form.types';

export interface ProductVariantModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: ProductVariantFormValues) => void;
  attributes: Attribute[];
  values?: ProductVariantFormValues;
}


export const initialValues: ProductVariantFormValues = {
  name: '',
  ean: '',
  sku: '',
  price: 0,
  stock: 0,
  default: false,
  attributes: {},
  photos: [] as File[],
};

const sortAttributesByType = (attributes: Attribute[]) => {
  const order = ['dictionary', 'string', 'number', 'boolean'];
  return [...attributes].sort((a, b) => {
    return order.indexOf(a.valueType) - order.indexOf(b.valueType);
  });
};

export const ProductVariantModal = ({
  open,
  onClose,
  attributes,
  onSubmit,
  values,
}: ProductVariantModalProps) => {
  const isUploadingPhoto = useIsMutating({
    mutationKey: ['uploadProductPhoto'],
  });
  const { control, handleSubmit } = useForm<ProductVariantFormValues>({
    defaultValues: values || initialValues,
    resolver: yupResolver(productVariantSchema, { recursive: true }),
    shouldUnregister: true,
  });

  const onSubmitHandler: SubmitHandler<ProductVariantFormValues> = async (
    data
  ) => {
    onSubmit(data);
  };

  return (
    <Modal open={open} onClose={onClose} closeable>
      <Form>
        <div>
          <Header>
            <Heading>Informacje o wariancie</Heading>
          </Header>
          <BasicInfo>
            <Row columns={['2fr', '1fr', '1fr']} gap={12}>
              <TextField control={control} name="name" label="Nazwa wariantu" />
              <TextField control={control} name="ean" label="EAN" />
              <TextField control={control} name="sku" label="SKU" />
            </Row>
            <Row columns={['1fr', '1fr']} gap={12}>
              <TextField control={control} name="price" label="Cena" />
              <TextField
                control={control}
                type="number"
                name="stock"
                label="Stan magazynowy"
              />
            </Row>
            <CheckBox
              control={control}
              name="default"
              label="Domyślny"
              value="default"
            />
          </BasicInfo>
          {!!attributes.length && (
            <>
              <Header>
                <Heading>Atrybuty</Heading>
              </Header>

              <InfoTable>
                {sortAttributesByType(attributes).map((attr) => (
                  <TableRow key={attr.uuid}>
                    <Cell>{attr.name}</Cell>
                    <Cell>
                      {renderAttributeField(
                        attr,
                        control,
                        `attributes.${attr.uuid}`
                      )}
                    </Cell>
                  </TableRow>
                ))}
              </InfoTable>
            </>
          )}
        </div>
        <div>
          <Header>
            <Heading>Zdjęcia</Heading>
          </Header>
          <Photos>
            {Array.from({ length: 8 }).map((_, idx) => (
              <PhotoInput key={idx} control={control} name={`photos.${idx}`} />
            ))}
          </Photos>
          <div>
            <Button
              disabled={!!isUploadingPhoto}
              pending={!!isUploadingPhoto}
              type="button"
              label="Dodaj wariant"
              size="medium"
              onClick={handleSubmit(onSubmitHandler)}
            />
          </div>
        </div>
      </Form>
    </Modal>
  );
};
