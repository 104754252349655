import { Page } from '../../../shared/components/page';
import { PageHeader } from '../../../shared/components/page-header';
import { AttributeForm } from '../../forms/attribute-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { getValidationErrors } from '../../../shared/utils/get-validation-errors';
import { useState } from 'react';
import { useProtectedRoute } from 'src/auth/hooks/use-protected-route';
import { Role } from 'src/auth/hooks/use-can';
import { useCreateAttributeMutation } from 'src/attributes/gql/mutations/create-attribute.mutation';
import { formValuesToApiData } from 'src/attributes/forms/attribute-form/attribute-form.mappers';
import { paths } from 'src/shared/constants/paths';

export const CreateAttribute = () => {
  useProtectedRoute([Role.Admin, Role.Manager]);
  const [errors, setErrors] = useState<Record<string, string> | undefined>(
    undefined
  );
  const navigate = useNavigate();
  const location = useLocation();

  const [mutate] = useCreateAttributeMutation({
    onCompleted() {
      navigate(location.state?.redirect || paths.attributes.list());
    },
    onError(e) {
      setErrors(getValidationErrors(e));
    },
  });

  return (
    <Page width={40}>
      <PageHeader title="Create Attribute" canGoBack />
      <AttributeForm
        onSubmit={(data) => {
          mutate({ variables: { data: formValuesToApiData(data) } });
        }}
        submitLabel="Create attribute"
        errors={errors}
      />
    </Page>
  );
};
