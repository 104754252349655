import { gql, TypedDocumentNode, useMutation } from '@apollo/client';

export interface DeleteProductVariables {
  uuid: string;
}

export interface DeleteProductData {
  deleteProduct: {
    uuid: string;
    name: string;
    slug: string;
    description: string;
    category: {
      uuid: string;
    };
  };
}

export const DeleteProductMutation: TypedDocumentNode<
  DeleteProductData,
  DeleteProductVariables
> = gql`
  mutation DeleteProductMutation($uuid: String!) {
    deleteProduct(uuid: $uuid) {
      uuid
    }
  }
`;

export const useDeleteProductMutation = () => {
  return useMutation<DeleteProductData, DeleteProductVariables>(
    DeleteProductMutation
  );
};
