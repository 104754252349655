import {
  SuspenseQueryHookOptions,
  TypedDocumentNode,
  gql,
  useSuspenseQuery,
} from '@apollo/client';
import { Attribute } from 'src/shared/types/attribute.type';
import { FilterInput } from 'src/shared/types/filter-input.type';
import { SortInput } from 'src/shared/types/sort-input.type';

interface GetAttributesData {
  attributes: {
    payload: Attribute[];
    pagination: OffsetPaginationInfo;
  };
}

export interface OffsetPaginationInfo {
  page: number;
  pageSize: number;
  total: number;
  totalPages: number;
}

export interface PaginationQueryVariables {
  page: number;
  pageSize: number;
}

export interface GetAttributesQueryVariables {
  pagination: PaginationQueryVariables;
  sort?: SortInput;
  filters?: {
    name?: FilterInput;
  };
}

export const GetAttributesQuery: TypedDocumentNode<GetAttributesData> = gql`
  query GetAttributesQuery(
    $pagination: OffsetPaginationInput
    $filters: AttributesFiltersInput
    $sort: AttributesSortInput
  ) {
    attributes(pagination: $pagination, filters: $filters, sort: $sort) {
      pagination {
        total
        page
        pageSize
        totalPages
      }
      payload {
        uuid
        name
        unit
        displayName
        valueType
      }
    }
  }
`;

export interface UseGetAttributesQueryOptions {
  variables: GetAttributesQueryVariables;
}

export const GetAttributesQueryKey = 'GetAttributesQuery';

export const useGetAttributesQuery = (
  options?: SuspenseQueryHookOptions<
    GetAttributesData,
    GetAttributesQueryVariables
  >
) => {
  return useSuspenseQuery<GetAttributesData, GetAttributesQueryVariables>(
    GetAttributesQuery,
    options
  );
};
