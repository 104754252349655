import { PropsWithChildren } from 'react';
import { Wrapper } from './row.styles';

export interface RowProps {
  gap?: number;
  columns: string[];
}

export const Row = ({
  children,
  gap,
  columns,
}: PropsWithChildren<RowProps>) => {
  return (
    <Wrapper $columns={columns} $gap={gap}>
      {children}
    </Wrapper>
  );
};
