import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 680px;
`;

export const BasicInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Photos = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 24px;
  padding: 24px 0;
`;
