import { TypedDocumentNode, gql, useSuspenseQuery } from '@apollo/client';

export interface GetProductDetailsData {
  product: {
    uuid: string;
    name: string;
    slug: string;
    description: string;
    category: {
      uuid: string;
      name: string;
    };
    variants: {
      uuid: string;
      name: string;
      ean: string;
      sku: string;
      price: number;
      stock: number;
      default: boolean;
      photos: {
        uuid: string;
        fileUrl: string;
      }[];
      attributes: {
        uuid: string;
        name: string;
        value: string;
        attribute: {
          uuid: string;
          name: string;
          valueType: string;
        };
      }[];
    }[];
  };
}

export const GetProductDetailsQuery: TypedDocumentNode<GetProductDetailsData> = gql`
  query GetProductDetailsQuery($uuid: String!) {
    product(uuid: $uuid) {
      uuid
      name
      slug
      description
      category {
        uuid
        name
      }
      variants {
        uuid
        name
        ean
        sku
        price
        stock
        default
        attributes {
          uuid
          value
          attribute {
            uuid
            name
            valueType
          }
        }
        photos {
          uuid
          fileUrl
        }
      }
    }
  }
`;

export const useGetProductDetailsQuery = (uuid: string) => {
  return useSuspenseQuery<GetProductDetailsData>(GetProductDetailsQuery, {
    variables: { uuid },
  });
};
