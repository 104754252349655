import { Control, FieldPath } from 'react-hook-form';
import { CheckBox } from 'src/shared/components/checkbox';
import { Select } from 'src/shared/components/select';
import { TextField } from 'src/shared/components/textfield';

import {
  Attribute,
  CommonAttribute,
  DictionaryAttribute,
} from 'src/shared/types/attribute.type';
import { ProductVariantFormValues } from '../../product-form.types';

export const renderDictionary = (
  attr: DictionaryAttribute,
  control: Control<ProductVariantFormValues>,
  name: FieldPath<ProductVariantFormValues>
) => {
  return (
    <Select
      control={control}
      name={name}
      label=""
      options={attr.values.map((v) => ({
        label: v.value,
        value: v.uuid,
      }))}
    />
  );
};

export const renderString = (
  attr: CommonAttribute,
  control: Control<ProductVariantFormValues>,
  name: FieldPath<ProductVariantFormValues>
) => {
  return <TextField control={control} name={name} type="text" label="" />;
};

export const renderNumber = (
  attr: CommonAttribute,
  control: Control<ProductVariantFormValues>,
  name: FieldPath<ProductVariantFormValues>
) => {
  return <TextField control={control} type="number" name={name} label="" />;
};

export const renderBoolean = (
  attr: CommonAttribute,
  control: Control<ProductVariantFormValues>,
  name: FieldPath<ProductVariantFormValues>
) => {
  return <CheckBox control={control} name={name} label="" value="true" />;
};

export const renderAttributeField = (
  attribute: Attribute,
  control: Control<ProductVariantFormValues>,
  name: FieldPath<ProductVariantFormValues>
) => {
  switch (attribute.valueType) {
    case 'dictionary':
      return renderDictionary(attribute, control, name);
    case 'string':
      return renderString(attribute, control, name);
    case 'number':
      return renderNumber(attribute, control, name);
    case 'boolean':
      return renderBoolean(attribute, control, name);
  }
};
