import { Page } from 'src/shared/components/page';
import { PageHeader } from 'src/shared/components/page-header';
import { AttributeForm } from '../../forms/attribute-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useUpdateAttributeMutation } from 'src/attributes/gql/mutations/update-attribute.mutation';
import { paths } from 'src/shared/constants/paths';
import {
  apiDataToFormValues,
  formValuesToApiData,
} from 'src/attributes/forms/attribute-form/attribute-form.mappers';
import { useGetAttributeQuery } from 'src/attributes/gql/queries/get-attribute.query';

export const UpdateAttribute = () => {
  const { uuid = '' } = useParams<{ uuid: string }>();
  const { data } = useGetAttributeQuery({ uuid });
  const navigate = useNavigate();

  const location = useLocation();
  const [mutate] = useUpdateAttributeMutation({
    fetchPolicy: 'network-only',
    onCompleted: async () => {
      navigate(location.state?.redirect || paths.attributes.list());
    },
  });

  return (
    <Page>
      <PageHeader
        canGoBack
        title={`Update attribute: ${data.attribute.name}`}
      />
      <AttributeForm
        onSubmit={(values) => {
          mutate({
            variables: {
              uuid: String(uuid),
              data: formValuesToApiData(values),
            },
          });
        }}
        submitLabel="Update"
        values={apiDataToFormValues(data.attribute)}
      />
    </Page>
  );
};
