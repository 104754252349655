import { RouteObject } from 'react-router-dom';
import { CreateProduct } from './pages/create-product/create-product';
import { ListProducts } from './pages/list-products';
import { ShowProduct } from './pages/show-product';
import { UpdateProduct } from './pages/update-product';
import { paths } from 'src/shared/constants/paths';

export const productsRoutes: RouteObject[] = [
  { Component: ListProducts, path: paths.products.list() },
  { Component: ShowProduct, path: paths.products.show() },
  { Component: UpdateProduct, path: paths.products.edit() },
  { Component: CreateProduct, path: paths.products.create() },
];
