import {
  FetchPolicy,
  SuspenseQueryHookOptions,
  TypedDocumentNode,
  gql,
  useSuspenseQuery,
} from '@apollo/client';
import { Attribute } from 'src/shared/types/attribute.type';

export interface GetAttributeData {
  attribute: Attribute;
}

interface GetAttributeVariables {
  uuid: string;
}

export const GetAttributeQuery: TypedDocumentNode<GetAttributeData> = gql`
  query GetAttributeQuery($uuid: String!) {
    attribute(uuid: $uuid) {
      uuid
      name
      displayName
      valueType
      unit
      values {
        uuid
        value
      }
    }
  }
`;

export const GetAttributeQueryKey = 'GetAttributeQuery';

export interface UseGetAttributeQueryOptions {
  fetchPolicy?: FetchPolicy;
}

export const useGetAttributeQuery = (
  variables: GetAttributeVariables,
  options?: SuspenseQueryHookOptions<GetAttributeData, GetAttributeVariables>
) => {
  return useSuspenseQuery<GetAttributeData, GetAttributeVariables>(
    GetAttributeQuery,
    { variables, ...options }
  );
};
