import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 60%;
`;
export const InfoTable = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  align-items: center;
  padding-right: 20%;
`;

export const Cell = styled.div`
  @include type(headline, small);
  font-weight: 400;
  padding: 6px 0 6px 0;

  &:first-child {
    flex-basis: 20%;
  }

  &:last-child {
    flex-basis: 80%;
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
`;

export const PhotoThumbnail = styled.img`
  max-height: 40px;
  width: auto;
`;
