import { useState } from 'react';
import { Wrapper } from './attributes-picker.styles';
import { AttributesResults } from './attributes-results';
import { Suspense } from 'react';
import { TextField } from '@petmate/ui';
import { useDebounceValue } from 'src/shared/hooks/use-debounce-value.hook';

export const AttributesPicker = () => {
  const [search, setSearch] = useState('');
  const deferredSearch = useDebounceValue(search, 300);
  return (
    <Wrapper>
      <TextField
        label="Search"
        onChange={(e) => setSearch(e.target.value)}
        name="search"
        value={search}
        onBlur={() => {}}
      />
      <Suspense>
        <AttributesResults search={deferredSearch} />
      </Suspense>
    </Wrapper>
  );
};
