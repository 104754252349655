import { Attributes } from './attributes-picker.styles';
import { CheckBox } from 'src/shared/components/checkbox';
import { useFormContext } from 'react-hook-form';
import { ChangeEventHandler } from 'react';
import { CategoryFormValues } from 'src/categories/forms/category-form/category-form.types';
import { useGetAttributesQuery } from 'src/attributes/gql/queries/get-attributes.query';
import { FilterOperator } from 'src/shared/types/filter-input.type';

export const AttributesResults = ({ search }: { search: string }) => {
  const { getValues, setValue, control } = useFormContext<CategoryFormValues>();
  const { data, error } = useGetAttributesQuery({
    variables: {
      sort: { column: 'name', direction: 'asc' },
      pagination: { page: 1, pageSize: 1000 },
      filters: {
        name: {
          value: `%${search}%`,
          operator: FilterOperator.ilike,
        },
      },
    },
  });

  if (error) return <div>Error...</div>;

  const { payload } = data.attributes;

  const onAttributesChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { attributes } = getValues();
    const newAttributes = e.target.checked
      ? [...attributes, { uuid: e.target.value }]
      : attributes.filter((attribute) => attribute.uuid !== e.target.value);
    setValue('attributes', newAttributes);
  };

  return (
    <Attributes>
      {payload.map((attribute) => (
        <CheckBox
          label={attribute.name}
          value={attribute.uuid}
          onChange={onAttributesChange}
          control={control}
          name="attributes"
        />
      ))}
    </Attributes>
  );
};
