import {
  SuspenseQueryHookOptions,
  TypedDocumentNode,
  gql,
  useSuspenseQuery,
} from '@apollo/client';
import { Category } from 'src/shared/types/category.type';

import { FilterInput } from 'src/shared/types/filter-input.type';

interface GetPossibleProductCategoriesVariables {
  filters?: {
    parentUuid?: FilterInput;
  };
  relations?: string[];
}

interface GetPossibleProductCategoriesData {
  categories: Required<Category>[];
}

export const GetPossibleProductCategoriesQuery: TypedDocumentNode<GetPossibleProductCategoriesData> = gql`
  query GetPossibleProductCategoriesQuery(
    $filters: CategoriesFiltersInput
    $relations: [String!]
  ) {
    categories(filters: $filters, relations: $relations) {
      uuid
      name
      order
      attributes {
        uuid
        name
        valueType
        values {
          uuid
          value
        }
      }
    }
  }
`;

export interface UseGetPossibleProductCategoriesQuery extends SuspenseQueryHookOptions {
  variables?: GetPossibleProductCategoriesVariables;
}

export const useGetPossibleProductCategoriesQuery = (
  options: UseGetPossibleProductCategoriesQuery = {}
) => {
  return useSuspenseQuery<
    GetPossibleProductCategoriesData,
    GetPossibleProductCategoriesVariables
  >(GetPossibleProductCategoriesQuery, options);
};
